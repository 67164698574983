import { DateFormatType, ProductType } from "@/api/constants/enums";
import { i18n } from "../i18n";
import { Apartment } from "@/api/models/address/apartment.model";
import { ShoppingCartCharacteristic } from "@/models/shopping-cart.model";
import { ProductInstanceAction } from "@/constants/constants";
import { CustomerProductCharacteristic } from "@/api/models/customer";
import { ColorStatusType, ColorType } from "@/constants/colors.constants";
import {
	ProductTypeBackgroundColor,
	ProductTypeBorder,
	ProductTypeIconColor,
	ProductTypeTextColor,
	ProductTypeTextColorContrast
} from "@/constants/product-type-colors.constants";
import dayjs from "dayjs";
import { ConfigurationProductAction } from "@/api/models/configuration.model";
import {
	ProductTypeConfigurationActionBorder,
	ProductTypeConfigurationActionIcon,
	ProductTypeConfigurationActionText
} from "@/constants/product-configuration-colors.constants";
import {
	ProductTypeInstanceActionTextColor,
	ProductInstanceActionDefaultTextColor,
	ProductTypeInstanceActionBackgroundColor,
	ProductTypeInstanceActionBorder,
	ProductTypeInstanceActionIconColor,
	ProductTypeInstanceActionTextColorContrast
} from "@/constants/product-instance-colors.constants";

function getProductServiceCharacteristicByType({
	productType,
	serviceCharacteristicValue
}: {
	productType?: ProductType;
	serviceCharacteristicValue: string | Apartment;
}): string {
	if (productType !== ProductType.FIBRE) return String(serviceCharacteristicValue);

	const { streetType, streetName, streetNumber, block, entrance, floor, door, postcode, city, province, country, apartmentId } =
		serviceCharacteristicValue as Apartment;

	const plainAddress = [
		[streetType, streetName].filter(Boolean).join(" "),
		streetNumber,
		block,
		entrance,
		floor,
		door,
		postcode,
		city,
		province,
		country
	]
		.filter(Boolean)
		.join(", ")
		.toLocaleUpperCase();
	if (!apartmentId) return plainAddress;

	return `${plainAddress} [${apartmentId}]`;
}

function getProductDisplayName({
	productType,
	displayName,
	instanceName,
	isBundle = false
}: {
	productType?: ProductType;
	displayName: string;
	instanceName?: string;
	isBundle?: boolean;
}): string {
	if (isBundle || productType !== ProductType.FIBRE || !instanceName) return displayName;

	return `${displayName} [${instanceName}]`;
}

function getProductInstanceInfo({
	productType,
	productQty = 1,
	serviceCharacteristicName,
	serviceCharacteristicValue,
	action
}: {
	productType?: ProductType;
	productQty?: number;
	serviceCharacteristicName?: string;
	serviceCharacteristicValue?: string | Apartment;
	action?: ProductInstanceAction;
}): string {
	if (!serviceCharacteristicName) return "";

	const label = i18n.t(`customers.products.service_specification.${serviceCharacteristicName}`);
	const useContrast = productQty === 0;
	const value = serviceCharacteristicValue ? getProductServiceCharacteristicByType({ productType, serviceCharacteristicValue }) : "";
	const actionTextColor = productType
		? useContrast
			? ProductTypeInstanceActionTextColorContrast[productType]
			: ProductTypeInstanceActionTextColor[productType]
		: ProductInstanceActionDefaultTextColor;
	const valueTextColor = action ? actionTextColor[action] : getProductTextColor({ productType, useContrast });

	return `${label}: <b style="color: ${valueTextColor}">${value}</b>`;
}

function getProductServiceCharacteristicValue({
	productType,
	serviceCharacteristicValue,
	action
}: {
	productType?: ProductType;
	serviceCharacteristicValue?: string;
	action?: ProductInstanceAction;
}): string {
	if (productType !== ProductType.MOBILE && productType !== ProductType.VOIP) return "";

	const value = serviceCharacteristicValue ? getProductServiceCharacteristicByType({ productType, serviceCharacteristicValue }) : "";
	const actionTextColor = productType ? ProductTypeInstanceActionTextColor[productType] : ProductInstanceActionDefaultTextColor;
	const valueTextColor = action ? actionTextColor[action] : getProductTextColor({ productType });

	return `<b style="color: ${valueTextColor}">${value}</b>`;
}

function getProductBackgroundColor({
	productType,
	disabled = false,
	action
}: {
	productType?: ProductType;
	disabled?: boolean;
	action?: ProductInstanceAction;
}): string {
	if (disabled) return ColorStatusType.GREY_LIGHT;
	if (!productType) return "";
	if (!action) return ProductTypeBackgroundColor[productType];

	const prodyctTypeInstanceBackgroundColors = ProductTypeInstanceActionBackgroundColor[productType];
	return prodyctTypeInstanceBackgroundColors[action];
}

function getProductBorder({ productType, action }: { productType?: ProductType; action?: ProductInstanceAction }): string {
	if (!productType) return "";
	if (!action) return ProductTypeBorder[productType];

	const prodyctTypeInstanceBorders = ProductTypeInstanceActionBorder[productType];
	return prodyctTypeInstanceBorders[action];
}

function getProductTextColor({ productType, useContrast = false }: { productType?: ProductType; useContrast?: boolean }): string {
	if (!productType) return "";

	return useContrast ? ProductTypeTextColorContrast[productType] : ProductTypeTextColor[productType];
}

function getProductIconColor({ productType, action }: { productType?: ProductType; action?: ProductInstanceAction }): string {
	if (!productType) return "";
	if (!action) return ProductTypeIconColor[productType];

	const prodyctTypInstanceIconColors = ProductTypeInstanceActionIconColor[productType];
	return prodyctTypInstanceIconColors[action];
}

function buildCharacteristicDescription({
	amount = 0,
	bindingDate,
	bindingMonths = 0,
	completedPercentage = 0
}: {
	amount?: number;
	bindingDate?: string;
	bindingMonths?: number;
	completedPercentage?: number;
}): string {
	const description = [`${amount.toFixed(2)}€`];

	if (bindingDate && dayjs(bindingDate).isValid()) {
		const bindingDateDescription = `${i18n.t("common.until")} ${dayjs(bindingDate).format(DateFormatType.SHORT_DATE_TIME)} `;
		description.push(bindingDateDescription);
		return description.join(" ");
	}

	if (bindingMonths) {
		const duringMonths = bindingMonths > 1 ? "common.months" : "common.month";
		const monthsDescription = `${i18n.t("common.during")} ${bindingMonths} ${i18n.t(duringMonths)}`;
		description.push(monthsDescription);
	}

	if (completedPercentage) {
		const completedPercentageDescription = `(${completedPercentage.toFixed(2)}%)`;
		description.push(completedPercentageDescription);
	}

	return description.join(" ");
}

function getShoppingCharacteristicDescription({ characteristic }: { characteristic: ShoppingCartCharacteristic }): string {
	if (!characteristic) return "0.00€";

	const { charge, completedPercentage, prices } = characteristic;
	if (!charge) return "0.00€";

	const { amount, months: bindingMonths } = charge;
	const characteristicAmount = prices && prices.length > 0 && prices[0].amount ? prices[0].amount : amount;
	const formattedBindingDate = prices && prices.length > 0 && prices[0].bindingDate ? dayjs(prices[0].bindingDate) : undefined;
	return buildCharacteristicDescription({
		amount: Number(characteristicAmount),
		bindingDate: formattedBindingDate && formattedBindingDate.isValid() ? formattedBindingDate.format(DateFormatType.SHORT_DATE_TIME) : undefined,
		bindingMonths,
		completedPercentage
	});
}

function getCustomerProductCharacteristicDescription({ characteristic }: { characteristic: CustomerProductCharacteristic }): string {
	if (!characteristic) return "0.00€";

	const { displayAmount: amount, bindingMonths, completedPercentage } = characteristic;

	return buildCharacteristicDescription({ amount, bindingMonths, completedPercentage });
}

// Configuration
function getConfigurationProductBackgroundColor({ productType }: { productType: ProductType; disabled?: boolean }): string {
	if (productType == ProductType.FIBRE) return ColorType.PURPLE;
	return "";
}
function getConfigurationProductBorderColor({ productType, action }: { productType?: ProductType; action?: ConfigurationProductAction }): string {
	if (!productType || !action) return "black-border";
	if (productType == ProductType.FIBRE) return "purple-border";

	return ProductTypeConfigurationActionBorder[productType][action];
}

function getConfigurationProductIconColor({ productType }: { productType?: ProductType }): string {
	if (!productType) return "black";

	return ProductTypeConfigurationActionIcon[productType];
}

function getConfigurationProductTextColor({ productType }: { productType?: ProductType }): string {
	if (!productType) return "black-color";

	return ProductTypeConfigurationActionText[productType];
}

export const ProductUtil = {
	getCustomerProductCharacteristicDescription,
	getProductBackgroundColor,
	getProductBorder,
	getProductDisplayName,
	getProductIconColor,
	getProductInstanceInfo,
	getProductServiceCharacteristicValue,
	getProductTextColor,
	getShoppingCharacteristicDescription,
	getConfigurationProductBackgroundColor,
	getConfigurationProductIconColor,
	getConfigurationProductTextColor,
	getConfigurationProductBorderColor
};
